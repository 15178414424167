<template>
	<div>
		<h1>Quote Forms</h1>

		<div class="row">
			<div class="col-12 col-lg-4 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>M1 Motorcycle Quote</b></div>
					<div class="card-body d-flex justify-content-between">
						<a href="https://mitch.nerivon.cloud/mcquote" target="_blank" class="w-50 text-center"><i class="fa-regular fa-person-falling-burst fa-5x"></i><br>Development</a>
						<a href="https://mitchinsurance.com/motorcycle-insurance/m1-quote" target="_blank" class="w-50 text-center"><i class="fa-regular fa-motorcycle fa-5x"></i><br>Production</a>
					</div>
					<div class="card-footer text-center">M1 Motorcycle Quotes From Facility</div>
				</div>
			</div>
			<div class="col-12 col-lg-4 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>Automotive Quote</b></div>
					<div class="card-body d-flex justify-content-between">
						<a href="https://mitch.nerivon.cloud/autoquote" target="_blank" class="w-50 text-center"><i class="fa-regular fa-car-burst fa-5x"></i><br>Development</a>
						<a href="https://mitchinsurance.com/auto-insurance/quote" target="_blank" class="w-50 text-center"><i class="fa-regular fa-car-side fa-5x"></i><br>Production</a>
					</div>
					<div class="card-footer text-center">Automotive Quotes From FORTUS</div>
				</div>
			</div>
			<div class="col-12 col-lg-4 mb-4">
				<div class="card">
					<div class="card-header text-center"><b>All-In-One Quote</b></div>
					<div class="card-body d-flex justify-content-between">
						<a href="https://mitch.nerivon.cloud/aioquote" target="_blank" class="w-50 text-center"><i class="fa-regular fa-house-fire fa-5x"></i><br>Development</a>
						<a href="https://mitchinsurance.com" target="_blank" class="w-50 text-center"><i class="fa-regular fa-car-building fa-5x"></i><br>Production</a>
					</div>
					<div class="card-footer text-center">All-In-One Quotes From FORTUS, coming some day.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';

	export default {
		name: 'Quotes',
		computed: {
			...mapGetters([
				"user"
			])
		}
	}
</script>
